.scrolling-numbers {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100px;
  height: 100px;
}

.inner-circle {
  background-color: lightblue;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: 25px; /* Adjust this value to center the inner circle */
}

.number-scroll {
  animation: scroll 5s linear infinite;
}

.number {
  font-size: 20px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.number.selected {
  background-color: transparent;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}




/* styles.css */

.social-bar {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.social-icon {
  color: #fff; /* Set your desired icon color */
  background-color: #333; /* Set your desired background color */
  padding: 10px;
  border-radius: 10px; /* Set border-radius to create rounded corners */
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

/* Customize background colors for each social icon */
.social-icon.facebook {
  background-color: #1877f2; /* Facebook blue */
}

.social-icon.whatsapp {
  background-color: #25d366; /* WhatsApp green */
}

.social-icon.instagram {
     background-color: #fd4296; /* WhatsApp green */
  
}

.social-icon:hover {
  background-color: #8c99ac; /* Set your desired hover background color */
}


div.inline {
  display: inline;
}

button.inline {
  display: inline;
}
