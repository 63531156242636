
@media print{
    .pdf-container{
        display:none;
    }
}
.hide-controls::-webkit-media-controls {
  display: none !important;
}

.hide-controls::-webkit-media-controls-start-playback-button {
  display: none !important;
}






