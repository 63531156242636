/* Chapter.css */
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  height: 0;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

.grid-item {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px;
  transition: transform 0.3s;
}

.grid-item:hover {
  transform: scale(1.05);
}

.grid-item img {
  width: 100%;
  border-radius: 15px;
}


/* styles.css */

.autocomplete-container {
  padding: 3rem 4.5rem;
  background-color: #ffffff;
  /* light gray background */
}

.autocomplete-input {
  width: 100%;
  max-width: 32rem;
  /* matches Tailwind's max-w-lg */
  margin: 0 auto;
  /* centers the element */
  padding: 0.5rem 1rem;
  /* adjust as needed */
  border-radius: 1rem;
  /* matches Tailwind's rounded-2xl */
  border: 1px solid #d1d5db;
  /* light gray border */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  /* matches Tailwind's shadow-lg */
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.autocomplete-input:hover {
  border-color: #2563eb !important;
  /* slightly darker blue on hover */
}

.autocomplete-input:focus {
  border-color: #3b82f6 !important;
  /* blue border on focus */
  box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.3);
  /* blue ring on focus */
}

.ais-Breadcrumb-item--selected,
.ais-HierarchicalMenu-item--selected,
.ais-Menu-item--selected {
  font-weight: semibold;
}

/* ... */
.custom-autocomplete-item {

  border-bottom: 1px solid #e6f1ff;
}

.custom-autocomplete-item:hover {
  background-color: #eef5ff;
}

.custom-autocomplete-item img {
  width: 56px; /* Width for .w-14 */
  height: 56px; /* Height for .h-14 */
  border-radius: 0.375rem; /* Border-radius for .rounded */
  margin-right: 1rem; /* Margin-right for .mr-4 */
}

.custom-autocomplete-item .gap-4 {
  display: flex;
  flex-direction: column;
  gap: 0.25rem; /* Space between items */
}

.custom-autocomplete-item .font-medium {
  font-weight: 500;
}

.algolia-autocomplete-theme-classic .aa-Input {
  border: 2px solid #333333;
  padding: 8px;
  font-size: 14px;
}


.ais-Hits-list { display: grid; grid-template-columns: repeat(1, 1fr); grid-gap: 1em; }
.ais-Hits-item img { margin-right: 1em; }

.ais-Hits-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  padding-left: 2rem;
  padding-right: 2rem;
  
}

.ais-Hits-item img {
  margin-right: 1em;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .ais-Hits-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .ais-Hits-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .ais-Hits-list {
    grid-template-columns: 1fr;
  }
}


