/* Style for the button-like radio inputs */
.button-label {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #f0f0f0;
    border: 2px solid #ffffff;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.button-label:hover {
    background-color: #e0e0e0;
}

/* Style for the checked radio input */
input[type="radio"]:checked+.button-label {
    background-color:#000000;
    color: #fff;
    border-color: #ffffff;
    width: 100%;
}