@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    /* background-image: url(./components/wave\ \(2\).svg); */
    /* background-repeat:inherit; */
    background-size: 2000px;
    background-position: 90% -105%;
    padding: 0;
    margin: 0;
}

.section {
    width: 100%;
    height: 100;

    /* background: url(./wave\ \(5\).svg); */
    background-size: cover;
    background-blend-mode: screen;
}

/* .section1 {
    width: 100%;
    height: 100;
    padding: 5rem;
    background: url(./wave\ \(10\).svg);
    background-size: cover;
    background-blend-mode: screen;
} */

/* #cta{
      background-image: url(./components/wave\ \(2\).svg);
}
.custom-shape-divider-top-1690309750 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-top-1690309750 svg {
    position: relative;
    display: block;
    width: calc(132% + 1.3px);
    height: 130px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1690309750 .shape-fill {
    fill: #FEEC15;
} */

/** For tablet devices **/
.wave {
    position: absolute;
    bottom: 0;
    left: 0;

}

@media(max-width:991px) {
    .wave {
        display: none;
    }

    .section {
        padding: 2rem;

    }
}