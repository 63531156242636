@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 1s linear infinite;
}

.content {
  font-family: monospace;
  /* Use a monospace font for consistent spacing */
}

.content pre {
  white-space: pre-wrap;
  /* Preserve white space */
}

.content pre::before {
  content: '·';
  /* Middle dot */
  margin-right: 5px;
  /* Space between dot and content */
}