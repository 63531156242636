/* For screens smaller than 640px (mobile) */
@media (max-width: 640px) {
  .banner {
    height: 150px; /* Adjust the height for mobile screens */
    background-size: cover; /* Ensure the image fully contains the container */
    border-radius: 15px; /* Add a corner radius for mobile screens */
  }
}

/* For screens between 641px and 1024px (tablet) */
@media (min-width: 641px) and (max-width: 1024px) {
  .banner {
    height: 350px; /* Adjust the height for tablet screens */
    background-size: cover; /* Ensure the image fully contains the container */
    border-radius: 15px; /* Add a corner radius for tablet screens */
  }
}

/* For screens larger than 1024px (desktop) */
@media (min-width: 1025px) {
  .banner {
    height: 350px; /* Adjust the height for desktop screens */
    background-size: cover; /* Ensure the image fully contains the container */
    border-radius: 15px; /* Add a corner radius for desktop screens */
  }
}
